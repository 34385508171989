.top__header {
  background-color: var(--primary400);
  padding: 10px 0;
}
.contact__links li a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact__links li a span {
  display: none;
}
.top__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact__links {
  display: flex;
  gap: 25px;
  align-items: center;
}

@media screen and (min-width: 991px) {
  .contact__links li a span {
    display: block;
  }
}
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: none;
}

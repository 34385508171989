:root {
  --primary400: #bf2e1a;
  --primary500: #9f2616;
  --secondary400: rgba(39, 121, 113, 0.7);
  --secondary100: #ebfeff;
  --gray: #dadada;
  --gray400: #ddd;
  --gray500: #f7f7f7;
  --gray600: #808080;
  --gradientColor1: #00249d;
  --gradientColor2: #002c37;
  --gradientColor3: #460800;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--gray);
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  background-color: var(--gradientColor2);
  border-radius: 0;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary400);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: "Catamaran";
  src: url(./assets/fonts/Catamaran-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Catamaran";
  src: url(./assets/fonts/Catamaran-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Catamaran";
  src: url(./assets/fonts/Catamaran-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Catamaran";
  src: url(./assets/fonts/Catamaran-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: "Creame Cake";
  src: url(./assets/fonts/cream_cake/Cream\ Cake.otf);
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Catamaran" !important;
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}
* {
  box-sizing: border-box;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.bg-gray {
  background-color: var(--gray500);
}
.object-cover {
  object-fit: cover;
}
.object-contain {
  object-fit: contain;
}
.list-style-none {
  list-style: none;
}
.column_count_2 {
  column-count: 2;
}
.lh-0 {
  line-height: 0;
}
.bg-custom-primary {
  background-color: var(--primary400);
}
.navbar a.nav-link {
  color: #000;
  border-radius: 0;
  border-bottom: none;
  width: 100%;
  text-align: right;
  border-bottom: solid 1px var(--gray400);
}
.navbar a.nav-link:last-child {
  border: none;
}

.navbar a.nav-link.active {
  border-bottom: solid 2px var(--primary400);
}
.navbar .navbar-nav {
  gap: 0;
  align-items: center;
  transition: 0.5s ease;
}
button.navbar-toggler {
  border: none;
}
button.navbar-toggler:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.social__links {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* media page */

.social__links li a {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  color: var(--primary400);
  font-size: 15px;
}
.social__icons {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}
.social__icons a {
  font-size: 20px;
  margin-bottom: 10px;
  text-decoration: none;
  color: white;
  /* border-radius: 60%; */
  border-radius: 100%;
  background-color: var(--primary400);
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.social__icons {
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  transition: transform 0.3s ease-in-out;
  animation: slide 5s linear infinite;
}
.social__slider {
  overflow: hidden;
  width: 100%;
}
.social__icons:hover {
  color: rgb(234, 146, 146);
}
@keyframes slide {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
.city__address li {
  list-style: none;
  text-align: right;
  font-weight: bold;
}
.city__address {
  padding-top: 30px;
  width: 380px;
}
.Social_Links {
  align-items: center;
}
.footer__item {
  /* background-color: black; */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .slide-in {
  transform: translateX(0);
}
.slide-out{
  transform: translateX(100%);
} */
.close__btn {
  background-color: var(--primary400);
  border-radius: 5px;
  align-items: center;
  border: none;
}
/* .icon__container{
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: grey;
  transition: background-color 0.3s ease;
}
.icon__container:hover{
background-color: #053742;
}
.icon__container a {
  text-decoration: none;
}
.icon__container a:hover{
  text-decoration: underline;
} */
.video__bg {
  width: 100%;
  height: 500px;
}
h2,
h3,
h4 {
  font-weight: 600;
}
.primary-color {
  color: var(--primary400) !important;
}
p {
  font-size: 16px;
  line-height: 1.5;
}
p:last-child {
  margin: 0;
}
section {
  padding: 50px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
}
.readmore__btn {
  background-color: var(--primary400);
  color: #fff;
  padding: 13px 45px;
  border-radius: 7px;
  text-decoration: none;
  margin-top: 30px;
  line-height: 1;
}
.readmore__btn.btn:hover {
  background-color: var(--primary500);
}
.readmore__btn__pd {
  background-color: var(--primary400);
  color: #fff;
  padding: 13px;
  border-radius: 7px;
  text-decoration: none;
  margin-top: 30px;
  line-height: 1;
  width: 180px;
  text-align: center;
}
section,
header,
footer {
  width: 100%;
  float: left;
}
.header__logo {
  object-fit: contain;
  height: 25px;
  width: auto;
  transition: 0.5s ease;
}
header .right__bar {
  display: flex;
  gap: 20px;
  align-items: center;
}
header .right__bar button.header_popbtn {
  background-color: #fff;
  color: var(--primary400);
  padding: 3px 5px;
  border: none;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
}
header .right__bar > a {
  text-decoration: none;
}
/* about images */
.about__images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}
.about__images img {
  max-width: 100%;
  height: auto;
}
.tabs-history {
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px;
  color: var(--primary400);
  background-color: rgb(245, 245, 245);
  column-gap: 20px;
  margin-left: 10px;
}
ul.tabs-history li {
  flex: 1;
}

ul.tabs-history li button {
  width: 100%;
}
.align-items-center img {
  border-radius: 10px;
}
.title_wrap {
  font-size: 700px;
  text-align: center;
  align-items: center;
}
.title_wrap h2 {
  font-weight: 700px;
  color: var(--primary400);
}
.loader {
  height: 30px;
}
.form__wrapper {
  background-color: rgb(206, 170, 170);
  margin-top: 20px;
}
.form__wrapper h2 {
  text-align: center;
}

.title__wrap {
  position: relative;
}
.title__wrap h2 {
  font-weight: 700;
}
.title__wrap h2 span br {
  text-align: justify;
  margin-right: 10px;
}
.title__wrap h2 span {
  color: var(--primary400);
  display: block;
  margin-top: 20px;
}
.title__wrap.regular h2 {
  font-size: 35px;
}
.led_list ul li {
  list-style: none;
}

/* .first__product__en7p {
  background-image: url(./assets/images/splash.webp);
  background-size: 260px 300px;
  background-repeat: no-repeat;
  padding: 20px;
} */
/* .second__product__1o400u {
  background-image: url(./assets/images/splash.webp);
  background-size: 260px 300px;
  background-repeat: no-repeat;
  padding: 20px;
}
.third__product__sms230 {
  background-image: url(./assets/images/splash.webp);
  background-size: 260px 300px;
  background-repeat: no-repeat;
  padding: 20px;
}
.foruth__product__xblue {
  background-image: url(./assets/images/splash.webp);
  background-size: 260px 300px;
  background-repeat: no-repeat;
  padding: 20px;
} */

section.color_led_display {
  background-image: url(./assets/images/ledbg.webp);
  border-radius: 10px solid var(--primary400);
  opacity: 1;
}
section.differ__water__image {
  background-image: url(./assets/images/products/en-7p/differbg.webp);
  border-radius: 10px solid var(--primary400);
}
.title__wrap.center_aligned {
  text-align: center;
  position: relative;
}
.title__wrap.center_aligned::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: var(--primary400);
  position: absolute;
  bottom: 0;
}
.title__wrap.center_aligned h2 {
  padding-bottom: 17px;
}
.title__wrap span {
  color: var(--primary400);
  font-weight: 700;
  font-size: 1.5rem;
}
section.video__banner {
  padding: 0;
  margin: 0;
  top: 0;
  position: relative;
  width: 100%;
  float: left;
  height: auto;
  line-height: 0;
  /* background-color: #bf2e1a; */
}
section.video__banner video {
  object-fit: cover;
  border-radius: 20px;
}
section.video__banner a[role="button"] {
  width: 40px;
  height: 40px;
  opacity: 1;
  background-color: var(--primary400);
  top: 50%;
  border-radius: 50%;
}
section.video__banner a[role="button"] span {
  background-size: 20px;
}
section.video__banner .bannerText1,
section.video__banner .bannerText2,
section.video__banner .bannerText3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
section.video__banner .bannerText1 h2,
section.video__banner .bannerText2 h2,
section.video__banner .bannerText3 h2,
section.video__banner .bannerText4 h2 {
  font-weight: 800;
  font-size: 50px;
  line-height: 1;
  text-align: left;
  margin: 0;
}
section.video__banner .bannerText5 {
  right: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  text-align: center;
  max-width: 30%;
  width: 100%;
}
section.video__banner .bannerText5 h2 {
  text-transform: uppercase;
  color: #00cafa;
}
section.video__banner .bannerText5 h2 span {
  color: var(--primary400);
}
section.video__banner .bannerText1 {
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  text-align: center;
  max-width: 30%;
  width: 100%;
}
section.video__banner .bannerText1 h2 {
  text-transform: uppercase;
  color: #00cafa;
}
section.video__banner .bannerText1 h2 span {
  color: var(--primary400);
}
section.video__banner .bannerText2 {
  left: 100px;
  max-width: 30%;
  width: 100%;
}
section.video__banner .bannerText2 h2 {
  font-size: 40px;
}
section.video__banner .bannerText2 h2 span {
  color: var(--primary400);
  display: block;
  font-size: 50px;
}
section.video__banner .bannerText3 {
  max-width: 40%;
  width: 100%;
  right: 20%;
  bottom: 5%;
}

section.video__banner .bannerText3 h2 {
  color: #fff;
  /* color: var(--gradientColor3); */
}
section.video__banner .bannerText3 h2 strong {
  color: var(--primary400);
  display: block;
}
section.video__banner .bannerText3 h2 span {
  display: inline-block;
  width: auto;
  font-size: 30px;
  font-style: italic;
  background-color: rgb(49, 84, 158);
  padding: 5px 10px;
  border-radius: 15px 0 15px 0;
  font-weight: 400;
}
section.video__banner .bannerText4 {
  position: absolute;
  top: 10%;
  left: 5%;
  max-width: 60%;
  width: 100%;
}
section.video__banner .bannerText4 h2 {
  color: #fff;
  font-style: italic;
  font-size: 40px;
}
section.video__banner .bannerText4 h2 span {
  display: block;
  color: #00cafa;
  font-style: normal;
  font-size: 50px;
}

section.banner__text {
  background-color: #ffff;
  padding: 15px 0;
}
section.banner__text h2 {
  color: #313131;
  font-weight: 800;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  font-style: italic;
}
section.banner__text h2 span {
  font-family: "Creame Cake";
  color: var(--primary400);
  font-weight: 200;
  text-transform: capitalize;
  font-size: 35px;
  -webkit-text-stroke: 0.1px #000;
  -ms-text-stroke: 0.1px #000;
}
section.banner__text a[role="button"] {
  display: none;
}
section.who_we_are__wrapper {
  background-position: top;
}

section.who_we_are__wrapper .title__wrap h2 {
  margin-bottom: 40px;
  padding: 5px;
}
section.who_we_are__wrapper .title__wrap .about_image {
  object-fit: contain;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
}
section.who_we_are__wrapper .title__wrap .wave__img {
  height: 15px;
  object-fit: contain;
  position: absolute;
  bottom: -20px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}
section.live__bg__ionia {
  background-image: url(./assets/images/franchaises/water-splash-bg.webp);
  object-fit: cover;
  height: 400px;
}
.items {
  position: relative;
}
h2 {
  position: relative;
  color: #000;
}

section.slider__bg {
  background-image: url(./assets/images/franchaises/franchise_bg.webp);
  object-fit: cover;
  height: 700px;
}
section.comparison__water {
  background-image: url(./assets/images/franchaises/wavy_bg.webp);
  object-fit: cover;
  height: 700px;
}
section.performace_of_electrod__wrapper {
  background-image: url(./assets/images/performace-bg.webp);
}
section.performace_of_electrod__wrapper .thumb__image {
  width: 100%;
  object-fit: contain;
}
section.performace_of_electrod__wrapper .title__wrap h2 span {
  font-size: 40px;
}
section.silder__wrapper a[role="button"] {
  background-color: var(--primary400);
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
}
section.silder__wrapper a[role="button"] span {
  width: 1.5rem;
}
section.Slider_wrapper_franchise a[role="button"] {
  background-color: var(--primary400);
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
}
.text_camparison h2 {
  font-size: 40px;
}
section.product__wrapper__franchise::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(255 255 255 / 57%);
  z-index: -1;
}
section.product__wrapper__franchise .splash_bg {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 0;
}
section.product__wrapper__franchise img {
  width: 100%;
  position: relative;
  z-index: 1;
}
.first__product__en7p,
.second__product__1o400u,
.third__product__sms230,
.foruth__product__xblue {
  position: relative;
  width: 100%;
}
section.testimonials__wrapper {
  background-color: var(--gray500);
}

section.testimonials__wrapper .thumb {
  height: auto;
  border-radius: 3px;
  margin-bottom: 20px;
  width: 40px !important;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
section.testimonials__wrapper h3 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.32px;
  margin-bottom: 5px;
}
section.how_can_it_help .icon__wrap {
  color: var(--primary400);
  border-radius: 100px;
}
section.how_can_it_help .list__single {
  gap: 30px;
}
section.how_can_it_help .list__single .left_img {
  width: 190px;
  flex: 1 1;
  height: 170px;
}
section.how_can_it_help .list__single .left_img img {
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
}

section.testimonials__wrapper .rating {
  margin-bottom: 13px;
  color: var(--primary400);
  background-color: #d2d2d2;
  display: inline-flex;
  padding: 8px;
  border-radius: 100px;
}
section.testimonials__wrapper .rating img {
  height: 25px;
  margin-right: 5px;
}
section.testimonials__wrapper .owl-carousel .owl-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: -50px;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
}
section.testimonials__wrapper .owl-carousel .owl-nav button {
  background-color: var(--primary400);
  color: #fff;
  width: 30px;
  height: 30px;
}
section.testimonials__wrapper .owl-carousel .owl-dots {
  display: none;
}
section.why_ionia__wrapper {
  background-image: url(./assets/images/water_bg.webp);
  background-size: contain;
  background-position: bottom;
  padding: 60px 0;
}
section.why_ionia__wrapper .ionia_item {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 15px;
  margin-bottom: 18px;
}
section.why_ionia__wrapper .ionia_item:last-child {
  margin-bottom: 0;
}
section.why_ionia__wrapper .ionia_item .icon {
  width: 50px;
  height: 50px;
  font-size: 18px;
  color: #fff;
  background-color: var(--primary400);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
section.why_ionia__wrapper .ionia_item .icon img {
  object-fit: contain;
  width: 30px;
  height: 30px;
}
section.why_ionia__wrapper .ionia_item .content {
  flex: 1;
  color: var(--gray600);
}
section.why_ionia__wrapper .ionia_item .content h3 {
  font-weight: 600;
  font-size: 20px;
  color: #000;
}
section.different_php_level .content__box {
  background-color: var(--gray500);
  position: relative;
  left: 0;
  padding: 25px;
  line-height: 1.6;
  top: 0;
  transform: none;
  border-radius: 10px;
}
section.different_php_level .content__box h3 {
  font-size: 18px;
  font-weight: 700;
}
section.different_php_level .video_box {
  width: 100%;
  height: 500px;
}
section.different_php_level .video_box iframe {
  width: 100%;
  height: 100%;
}
section.exporting_countries__wrapper.bottom {
  background-image: url(./assets/images/exporting_countries_bg.webp);
  background-position: top;
  background-size: cover;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 80px;
}
section.exporting_countries__wrapper.bottom {
  padding-top: 90px;
}
section.exporting_countries__wrapper .title h2 {
  color: var(--gray600);
  font-size: 2.5rem;
  margin-bottom: 0;
}
section.exporting_countries__wrapper .title h2 span {
  font-weight: 800;
}
section.exporting_countries__wrapper .item {
  width: 120px;
  height: 120px;
  background-color: var(--secondary400);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto 15px;
  color: #fff;
  border-radius: 50%;
}
section.exporting_countries__wrapper .item h3 {
  font-weight: 700;
  font-size: 3rem;
  margin: 0;
}
section.exporting_countries__wrapper .item h3 span {
  font-size: 15px;
  font-weight: 400;
  margin-left: 5px;
}
input.form-control,
textarea.form-control,
input.form-check-input,
select.form-select {
  background-color: var(--gray);
  border: solid 1px var(--gray400);
  padding: 10px 15px;
  color: #000;
  resize: none;
}
input.form-check-input {
  padding: 0;
}
input.form-control:focus,
textarea.form-control:focus {
  box-shadow: none;
  background-color: var(--gray);
  border: solid 1px var(--primary400);
}
input.form-check-input:checked {
  background-color: var(--primary400);
  border: solid 1px var(--primary400);
  color: #fff;
}
input.form-check-input,
input.form-check-input {
  box-shadow: none;
}
section.contact_us__wrapper {
  background-color: var(--gray500);
}
section.contact_us__wrapper button {
  background-color: var(--primary400);
  border: solid 1px var(--primary400);
  padding-left: 40px;
  padding-right: 40px;
}
section.contact_us__wrapper button:hover {
  background-color: var(--primary400);
  border: solid 1px var(--primary400);
}
section.contact_us__wrapper .map {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.about__list__core__value {
  list-style: none;
  padding: 0;
  margin: 0;
  column-count: 2;
  column-gap: 40px;
  row-gap: 20px;
}

.list__single {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  margin: 0 0 20px;
}
.list__single span {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--primary400);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.list__single > div {
  flex: 1;
}
.list__single h3 {
  font-weight: 700;
  font-size: 18px;
}
section.certifications__wrapper .icon {
  height: 120px;
  width: 120px;
}
section.certifications__wrapper h4 {
  font-size: 16px;
  font-weight: 500;
  color: var(--gray600);
}

section.patent__wrapper {
  background-color: var(--gray500);
}
section.patent__wrapper img {
  cursor: pointer;
}
section.different_ph_levels__wrapper {
  overflow: hidden;
}

.demo {
  overflow: hidden;
}
.panel {
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.panel span {
  position: relative;
  display: block;
  overflow: visible;
  top: 50%;
  font-size: 80px;
}

.panel.section1 {
  background-color: #3883d8;
  z-index: 0;
}

section.different_ph_levels__wrapper h3 {
  font-weight: 600;
  color: #fff;
  background-color: var(--primary400);
  display: inline-block;
  font-size: 18px;
  padding: 10px 25px;
  border-radius: 5px;
  margin: 60px 0 30px;
}
.list__description li {
  column-count: 2;
  list-style-type: circle;
}
section.different_ph_levels__wrapper .item {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
section.different_ph_levels__wrapper .item h4 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 10px 0;
}
section.different_ph_levels__wrapper .item img {
  padding: 0 10px;
  background-color: #fff;
  margin: 10px 0;
}
.patent__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}
.patent__overlay .close {
  position: absolute;
  top: 30px;
  right: 30px;
  color: #fff;
  font-size: 30px;
  padding: 10px;
  cursor: pointer;
}
.patent__overlay .nextBtn,
.patent__overlay .prevBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 30px;
  padding: 10px;
  cursor: pointer;
}
.patent__overlay .prevBtn {
  left: 30px;
}
.patent__overlay .nextBtn {
  right: 30px;
}
.patent__overlay .modal_image {
  max-height: 80vh;
  width: auto;
  max-width: 900px;
  height: auto;
}
.reviews__list {
  height: 400px;
  overflow-y: auto;
  color: var(--gray600);
  padding: 10px;
}
.reviews__list::-webkit-scrollbar {
  display: none;
}
.reviews__list .item {
  background-color: #fff;
  box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 10px;
}
.reviews__list .item .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
}
.reviews__list .item .top h4 {
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  color: #000;
}
.reviews__list .item .top h4 span {
  color: var(--gray600);
  font-weight: 400;
}
.reviews__list .item .top .rating {
  color: var(--primary400);
}
section.inner__banner {
  position: relative;
  padding: 0;
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 30px;
}
section.inner__banner > .container {
  position: relative;
  z-index: 1;
}
section.inner__banner img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  object-fit: cover;
  z-index: 0;
}
section.inner__banner h1,
.contactus_banner h1 {
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
  margin: 0;
}
.section2 img {
  position: absolute;
  padding: 10px;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
}
.section3__wrap img {
  position: relative;
}
section.inner__banner.product_banner_wrapper {
  padding: 50px 0;
  height: auto;
  text-align: left;
  color: #fff;
}
section.inner__banner.product_banner_wrapper h1 {
  text-align: left;
}
section.inner__banner.product_banner_wrapper .thumb img {
  position: relative;
}
section.product__wrapper {
  background-image: url(../src/assets/images/products/product-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
section.product__wrapper section {
  background-color: transparent;
}
section.product__wrapper .first__image {
  position: relative;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 2;
}
section.product__wrapper .first__image img.product_image {
  height: 350px;
  position: relative;
  z-index: 1;
}
section.product__wrapper .first__image .splash {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.5;
}
section.product__wrapper section:nth-child(odd) .first__image {
  order: 1 !important;
}
section.product__wrapper section:nth-child(odd) .content__box {
  order: 2 !important;
}
.en7p__image {
  background-image: url(./assets//images/splash.webp);
  background-size: 335px 350px;
  background-repeat: no-repeat;
  padding: 20px;
}
.io400u_image {
  background-image: url(./assets//images/splash.webp);
  background-size: 335px 350px;
  background-repeat: no-repeat;
  padding: 20px;
}
.sms230_image {
  background-image: url(./assets//images/splash.webp);
  background-size: 335px 350px;
  background-repeat: no-repeat;
  padding: 20px;
}
.xblue_image {
  background-image: url(./assets//images/splash.webp);
  background-size: 335px 350px;
  background-repeat: no-repeat;
  padding: 20px;
}
.led__color {
  padding-left: 20px;
}
.color__led {
  box-sizing: border-box;
  border-radius: 20px;
}
.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.icon-style {
  background-color: #551b54;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}
.icon-style:hover {
  animation: none;
  background: #fff;
  color: #551b54;
  border: 2px solid #551b54;
}
section.title__wrap.splash img .first__img__wrapper h2 {
  text-align: center;
}
.properties__list h3 {
  color: var(--primary400);
}

.who_we_are__wrapper {
  /* background-image: url(./assets/images/aboutus//water-surface.webp); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
section.about_us_wrapper {
  background-image: url(./assets/images/aboutus/product.background.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.list__about {
  column-count: 2;
  column-gap: 20px;
}
.list__about li {
  column-gap: 20px;
  box-sizing: border-box;
  box-shadow: #000;
  margin: 20px;
}
/* .Tabs.ionia__event {
  width: 80%;
  height: auto;
  min-height: 400px;
  background: #053742;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: #e8f0f2;
  border-radius: 2rem;
} */

.Tabs {
  width: 80%;
  height: auto;
  min-height: 400px;
  background: #053742;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: #e8f0f2;
  border-radius: 2rem;
}
ul.tabs-history {
  margin: 10px;
  padding: 10px;
}

ul.tabs-history li.nav-item button {
  border: solid 2px var(--primary400);
  border-radius: 7px;
  padding: 6px 14px;
  color: #000;
}
ul.tabs-history li.nav-item button:hover {
  border: solid 2px var(--primary400);
  color: var(--primary400);
}
ul.tabs-history li.nav-item button.active {
  background-color: var(--primary400);
  color: #fff;
  border: solid 2px var(--primary400);
}
.sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
.sticky .header__logo {
  height: 15px;
}
.sticky .navbar a.nav-link.btn {
  padding: 5px 10px;
}
.sticky .navbar .navbar-nav {
  gap: 25px;
}
.bottom__header {
  border-bottom: solid 1px var(--gray400);
  transition: 0.5s ease;
}

/* section.what_is_alk__wrapper {
  background-color: var(--secondary100);
} */
section.mission_vision__wrapper {
  background-image: url(./assets/images/aboutus/mission-vision.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 400px;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}
section.our_networks__wrapper {
  background-color: var(--primary400);
  padding: 0;
}
section.our_networks__wrapper .map {
  line-height: 0;
  width: 100%;
  height: 100%;
}
section.our_networks__wrapper .list_wrap {
  padding: 90px;
}
section.health_benefit_banner {
  height: auto;
  text-align: left;
  padding: 90px 0;
}
section.health_benefit_banner img {
  top: 0;
  bottom: 0;
  transform: scale(1.5);
}
section.franchaises_bnner {
  width: 100%;
}
section.health_benefit_banner .video_box {
  width: 90%;
  margin-left: auto;
}
section.health_benefit_banner > .container {
  z-index: 2;
}
section.health_benefit_banner h1 {
  text-align: left;
}
section.health_benefit_banner h5,
section.health_benefit_banner p {
  color: #fff;
}
section.health_benefit_banner a.readmore__btn {
  margin-top: 20px;
}
section.why_does_it_happen__wrapper {
  background-color: var(--secondary100);
}
section.why_does_it_happen__wrapper .item {
  border: solid 2px var(--primary400);
  margin-top: 20px;
  text-align: center;
  padding: 20px;
}
section.why_does_it_happen__wrapper .item h3 {
  font-weight: 700;
  color: var(--primary400);
}
section.why_does_it_happen__wrapper .water_prop_img {
  border: solid 2px var(--primary400);
}
section.faq__wrapper .faq_tab {
  display: flex;
  gap: 40px;
}

section.faq__wrapper .faq_tab .tab-content {
  flex: 1;
  padding-right: 60px;
}

section.faq__wrapper .faq_tab ul.nav-tabs {
  flex-direction: column;
  width: 230px;
  padding-right: 20px;
  gap: 15px;
  border: none;
}

.faq__wrapper button.accordion-button {
  color: var(--gray600);
}
.faq__wrapper .accordion-item {
  border: none;
  border-bottom: solid 1px var(--gray400);
}
.faq__wrapper .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  font-weight: 600;
}
.faq__wrapper .accordion-button:focus:not(:focus-visible) {
  box-shadow: none;
}
.faq__wrapper .accordion-button::after {
  width: 25px;
  height: 25px;
  background-color: #d1d1d1;
  border-radius: 50%;
  background-position: center;
  background-size: 15px;
  background-image: url(./assets/images/down.webp);
}
.faq__wrapper .accordion-button:not(.collapsed)::after {
  background-color: var(--primary400);
  filter: none;
  background-image: url(./assets/images/up.webp);
}
section.faq__wrapper .faq_tab ul.nav-tabs button {
  background-color: var(--gray400);
  padding: 13px;
  color: #000;
  margin-right: 30px;
  position: relative;
  width: 100%;
  border-radius: 0;
  border: none;
  transition: 0.5s ease;
  padding-left: 30px;
  text-align: left;
}
section.faq__wrapper .faq_tab ul.nav-tabs button::after {
  position: absolute;
  content: "";
  right: -20px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-right: 0 solid transparent;
  border-left: 20px solid var(--gray400);
  border-bottom: 25px solid transparent;
  transition: 0.5s ease;
}
section.faq__wrapper .faq_tab ul.nav-tabs button.active {
  background-color: var(--primary400);
  color: #fff;
}
section.faq__wrapper .faq_tab ul.nav-tabs button.active::after {
  border-top: 25px solid transparent;
  border-right: 0 solid transparent;
  border-left: 20px solid var(--primary400);
  border-bottom: 25px solid transparent;
}
section.watch__demo {
  background-color: var(--primary400);
}
section.watch__demo .box {
  border: solid 3px #fff;
  padding: 50px 30px 30px;
}
section.watch__demo .video__box {
  margin: 20px 0 0;
  width: 100%;
  line-height: 1;
}
section.watch__demo .video__box video {
  width: 100%;
  object-fit: contain;
}
.connect_input {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.connect_input .inputbox {
  flex: 1;
  position: relative;
}
.connect_input .inputbox img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
}
.connect_input .inputbox input {
  border-radius: 10px 0 0 10px;
}
.connect_input button.submit {
  width: auto;
  border-radius: 0 10px 10px 0;
}
.connect_input button.submit:hover {
  background-color: #982414;
}
.connect_box h5 {
  text-align: center;
  font-weight: 600;
  margin: 0 0 20px;
}
.connect_box h5 span {
  display: block;
  color: var(--primary400);
}
.connect_box h5 span strong {
  color: #25d366;
  font-weight: 600;
}
section.blog_detail__wrapper {
  padding-top: 20px;
  color: var(--gray600);
}
.blog_detail__wrapper span.tagline {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--primary400);
  font-size: 18px;
  font-weight: 600;
}
.blog_detail__wrapper h1.blog_title {
  font-weight: 700;
  font-size: 25px;
  margin: 10px 0;
}
.blog_detail__wrapper .blog_info {
  margin: 0 0 18px;
  color: var(--gray600);
}
.blog_detail__wrapper h1,
.blog_detail__wrapper h2,
.blog_detail__wrapper h3,
.blog_detail__wrapper .blog_info strong {
  color: #000;
}
.blog_main__wrapper .blog__item {
  margin: 0 0 40px;
}
.blog_main__wrapper .blog__item .thumb {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 200px;
}
.blog_main__wrapper .blog__item .info {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  max-width: 90%;
  margin: -70px auto 0;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 6px #00000046;
}
.blog_main__wrapper .blog__item .info .dets {
  display: flex;
  gap: 20px;
  font-size: 12px;
  align-items: center;
  margin: 0 0 20px;
}
.blog_main__wrapper .blog__item .info .dets span {
  display: flex;
  align-items: center;
}
.blog_main__wrapper .blog__item .info .dets svg {
  margin-right: 5px;
  height: 10px;
}
.blog_main__wrapper .blog__item .info h3.blogTitle {
  font-weight: 600;
  font-size: 18px;
}
.blog_main__wrapper .blog__item .info h3.blogTitle a {
  color: #000;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog_main__wrapper .blog__item .info p {
  color: var(--gray600);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog_main__wrapper .blog__item .info a.readmore {
  background-color: transparent;
  color: var(--primary400);
  padding: 7px 20px;
  border-radius: 100px;
  text-decoration: none;
  font-size: 14px;
  border: solid 1px var(--primary400);
  line-height: 1;
}
.blog_main__wrapper .blog__item .info a.readmore:hover {
  background-color: var(--primary400);
  color: #fff;
}
.google_reviews {
  background-color: #fff;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgb(0 0 0 / 20%);
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1;
}
.google_reviews svg {
  fill: #f0de35;
}
.google_reviews strong {
  line-height: 1;
  font-size: 12px;
  color: gray;
}
.franchise_form__wrapper__box {
  /* background-color: rgba(22, 9, 27, 0.6); */
  border-radius: 10px;
  top: 0;
  opacity: 1;
  padding: 10px;
}
section.inner__banner.franchises__banner h1 {
  text-align: left;
  margin: 0 0 20px;
}
section.inner__banner.franchises__banner p {
  color: #fff;
  line-height: 1.6;
}
.franchise_form__wrapper__box h3 {
  /* color: rgb(15, 15, 15); */
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
  margin: 0 0 15px;
  text-align: left;
  border-color: black;
}
.franchise_form__wrapper__box input,
.franchise_form__wrapper__box textarea {
  /* background-color: #19191921; */
  border: white;
}
.franchise_form__wrapper__box button {
  background-color: var(--primary400);
  color: #fff;
  padding: 7px 45px;
  border: none;
}
section.form__banner {
  background-color: red;
}
section.form__banner img {
  height: 100%;
  margin-top: 10px;
}
.form__wrapper {
  align-items: center;
  padding: 10px;
  margin: 10px;
}
.form_select {
  margin-left: 10px;
  border-radius: 5px;
  margin-top: 40px;
  background-color: rgb(224, 218, 218);
  color: black;
}
.select__form {
  border-radius: 5px;
  text-align: left;
  background-color: rgb(224, 218, 218);
  color: black;
}
.form_time_investment {
  margin-left: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: rgb(224, 218, 218);
  color: black;
}
.franchises_list {
  column-count: 2;
  column-gap: 20px;
  padding: 0;
  margin: 0;
}
.background_img h2 .btn {
  text-align: center;
}
section.introduction__wrapper {
  background-image: url(./assets/images/performace-bg.webp);
  background-position: bottom;
  background-size: cover;
  height: auto;
}
section.definitions__wrapper {
  background-image: url(./assets/images/franchaises/franchise_bg.webp);
  background-position: bottom;
  background-size: cover;
  height: auto;
}

section.inner__banner.franchises__banner {
  background-image: url(./assets/images/banner.webp);
  background-position: bottom;
  background-size: cover;
  height: auto;
  padding: 50px 0 100px;
}
.franchises__banner > .container {
  position: relative;
  z-index: 1;
  left: 0;
  text-align: center;
}
section.live__ionia__water {
  background-image: url(./assets/images/help_img.webp);
}
section.live__ionia__water > .container.thumb_image img {
  max-width: 50px;
}
section.live__ionia__water .live_with_ionia {
  text-align: center;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  border-width: 10px;
  box-sizing: border-box;
  border-bottom: solid 5px var(--primary400);
  padding: 40px 25px;
  margin-top: -100px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
section.live__ionia__water .live_with_ionia h3 {
  color: var(--primary400);
  margin: 0 0 15px;
  font-weight: 600;
  font-size: 30px;
}

.franchises__banner h2 {
  text-align: center;
  color: var(--secondary100);
}
.franchises_live_wrap.background_wrap {
  position: relative;
  background: #2f5596;
}
.franchises_live_wrap::before {
  position: absolute;
  top: 10px;
}
section.rnd_product__wrapper ul.nav-tabs {
  display: flex;
  gap: 20px;
  border-bottom: solid 5px var(--primary400);
  margin: 0 auto 40px;
  left: 50%;
  transition: none;
}
section.rnd_product__wrapper ul.nav-tabs.menuscroll {
  position: fixed;
  max-width: 1140px;
  top: 70px;
  z-index: 99;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
section.rnd_product__wrapper ul.nav-tabs li.nav-item {
  flex: 1;
  text-align: center;
  border: none;
  border-bottom: solid 1px transparent;
}
section.rnd_product__wrapper ul.nav-tabs li.nav-item button {
  width: 100%;
  background-color: var(--gray400);
  line-height: 2;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
}
section.rnd_product__wrapper ul.nav-tabs li.nav-item button.active {
  background-color: var(--primary400);
  color: #fff;
  border: none;
}
.product_silder_images {
  width: 100px;
  height: 100%;
}
section.rnd_product__wrapper .product_detail_info .product_slide_image {
  box-shadow: none;
  border-radius: 0;
}
.product_detail_info .product_slide_image img.thumb__image {
  height: 380px;
}

section.super_strong__wrapper {
  background-image: url(./assets/images/rnd/super-strong-bg.webp);
  margin-bottom: 30px;
}
section.super_strong_wrapper {
  background-image: url(./assets/images/rnd/super-strong-bg.webp);
}
section.tft__wrapper {
  background-image: url(./assets/images/rnd/tft-bg.webp);
  padding-bottom: 0;
}
section.lemon_water__wrapper {
  background-image: url(./assets/images/waterbg2.webp);
  border-radius: 10px solid var(--primary400);
  transition: opacity 0.15s linear;
  z-index: 1;
  position: relative;
}
section.fruit_water__wrapper {
  background-image: url(./assets//images/waterbg3.webp);
  border-radius: 10px solid var(--primary400);
  position: relative;
}
section.watry_water__wrapper {
  background-image: url(./assets/images/waterbg2.webp);
  border-radius: 10px solid var(--primary400);
  position: relative;
}
section.watry_water__wrapper::after,
section.fruit_water__wrapper::after,
section.good_water__wrapper::after,
section.lemon_water__wrapper::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
  content: "";
  backdrop-filter: blur(1px);
}
section.good_water__wrapper {
  background-image: url(./assets/images/waterbg1.webp);
  border-bottom: 10px solid var(--primary400);
  position: relative;
}
.color__icons img {
  align-items: first baseline;
  background-color: rgb(158, 185, 237);
  padding-left: 20px;
  margin-right: 25px;
  box-sizing: border-box;
  box-sizing: 10px;
  border-radius: 20px;
}
section.performance__wrapper {
  position: relative;
  padding-bottom: 200px;
  overflow: hidden;
}
section.how_can_it_help {
  background-image: url(./assets/images/help_img.webp);
}
section.performance__wrapper img {
  height: 300px;
  width: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
section.conveniently_wrapper {
  background-image: url(./assets/images/franchaises/wavy_bg.webp);
}
section.filtration__wrapper {
  background-image: url(./assets/images/franchaises/franchise_bg.webp);
  margin-bottom: 35px;
}
section.performance__wrapper img.img1 {
  right: 0;
}
section.performance__wrapper img.img2 {
  left: 0;
}
section.performance__wrapper img.img3 {
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  top: inherit;
  transform: none;
  width: 100%;
}
ul.product_detail_info_list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.product_detail_info_list li {
  position: relative;
  margin: 5px 0;
  padding-left: 18px;
}

ul.product_detail_info_list li::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--primary400);
  position: absolute;
  left: 0;
  top: 8px;
  border-radius: 50%;
}

section.gallery__wrapper .gallery_img {
  height: 200px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 12px;
  width: 100%;
}
section.gallery__wrapper .gallery_img img {
  object-fit: cover;
  object-position: top;
}
section.gallery__wrapper .gallery_img:hover img {
  transform: scale(1.1);
}
section.gallery__wrapper .gallery__tabs__wrapper ul.nav-tabs,
section.gallery__wrapper .gallery__tabs__wrapper ul.nav-tabs button.nav-link {
  border: none;
}
section.gallery__wrapper .gallery__tabs__wrapper ul.nav-tabs {
  display: flex;
  gap: 20px;
  border-bottom: solid 5px var(--primary400);
  transition: none;
  margin: 0 0 50px;
}
section.gallery__wrapper .gallery__tabs__wrapper ul.nav-tabs button.nav-link {
  border-radius: 6px 6px 0 0;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  background-color: var(--gray400);
  border: none;
}

section.gallery__wrapper
  .gallery__tabs__wrapper
  ul.nav-tabs
  button.nav-link.active {
  background-color: var(--primary400);
  color: #fff;
  border: none;
}
section.gallery__wrapper .gallery__tabs__wrapper .tab-content {
  padding: 0;
}
button.scroll_top_btn {
  border: none;
  background-color: var(--primary400);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 99;
}
button.scroll_top_btn.hide {
  opacity: 0;
}
button.scroll_top_btn.show {
  opacity: 1;
}

.review_wrapper {
  background-color: #ebebeb;
  padding: 20px;
  border-radius: 10px;
}
.review_wrapper p {
  font-size: 20px;
  font-weight: 500;
}
.review_wrapper p svg {
  fill: var(--primary400);
}
.review_wrapper .rate {
  font-size: 13px;
  color: #9d9d9d;
}
.review_wrapper .rating {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #000;
  gap: 4px;
  background-color: #eee;
}
.review_wrapper .rating svg {
  fill: var(--primary400);
  margin-right: 2px;
}
.testimonials__wrapper .review_box {
  margin: 0 0 30px;
}
.testimonials__wrapper .review_box .comment {
  background-color: var(--gray400);
  padding: 20px;
  border-radius: 20px;
  text-align: left;
  position: relative;
}
.testimonials__wrapper .review_box .ratings {
  justify-content: flex-start;
  margin: 0 0 10px;
}
.testimonials__wrapper .review_box .ratings svg {
  fill: var(--primary400);
}
.testimonials__wrapper .review_box .bottom_info {
  display: flex;
  text-align: left;
  gap: 20px;
  margin-top: 30px;
  padding: 0 40px;
}
.testimonials__wrapper .review_box .bottom_info .info h3 {
  margin: 0;
}
.testimonials__wrapper .review_box .comment::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50px;
  width: 20px;
  height: 20px;
  border-right: solid 20px transparent;
  border-left: solid 10px transparent;
  border-bottom: solid 0 transparent;
  border-top: solid 20px var(--gray400);
}
.book_demo_form h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 17px;
}
h1.healthyLife_title span {
  display: block;
  margin-left: 27%;
}
section.rnd_inner_banner {
  height: 325px;
}
nav.blog_breadcrumb li a {
  text-decoration: none;
  color: var(--primary400);
}

.bottom__header.sticky {
  padding: 0;
  background-color: #fff;
}
.text__wrap h2 {
  text-align: center;
  font-size: 50px;
  color: var(--primary400);
  font-weight: 700;
}
.product_detail_info .product_slide_image {
  width: 80%;
  margin: 10px auto;
  box-shadow: 0px 1px 6px #9b9b9b54;
  border-radius: 10px;
  text-align: center;
}
.product_detail_info .tag {
  margin-bottom: 20px;
}
.product_detail_info .tag h4 {
  color: var(--gray600);
  font-size: 16px;
  margin: 0;
}
.title__wrap.regular h2.underline {
  border-bottom: solid 3px var(--primary400);
  padding: 0 0 8px;
  display: inline-block;
  margin: 0 0;
}
.product_detail_desc strong {
  color: var(--primary400);
  font-weight: 700;
}
.speci {
  width: 100%;
  overflow-x: auto;
}
.speci table {
  border-radius: 15px;
  overflow: hidden;
}
.speci table th {
  background-color: var(--primary400);
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}
.speci table strong {
  color: #000;
}
.speci::-webkit-scrollbar {
  display: none;
}
.review__form input,
.review__form textarea {
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
button.submit {
  background-color: var(--primary400);
  padding: 6px 30px;
  border: none;
  width: 40%;
}
button.submit:hover {
  background-color: var(--primary400);
}
button.submit:active {
  background-color: var(--primary500) !important;
}
label a {
  color: var(--primary400);
  text-decoration: none;
  font-weight: 600;
}
.contactus_banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 200px;
}
.contactus_banner::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.contactus_banner .container {
  position: relative;
  z-index: 1;
}
.contact_us__page_wrapper {
  background-color: #fff;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.161);
  margin-top: -110px;
  position: relative;
  z-index: 2;
}
.contact_us__page_wrapper .right_infobar {
  position: relative;
  background-color: var(--primary400);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact_us__page_wrapper .right_infobar h2 {
  font-weight: 700;
  font-size: 28px;
}
.contact_us__page_wrapper .right_infobar a {
  color: #fff;
  margin-left: 5px;
  text-decoration: none;
}
.contact_us__page_wrapper .right_infobar p {
  line-height: 1.7;
}
.contact_us__page_wrapper .switch__tabs .about_tab_image {
  position: absolute;
}
section.about__korea__india .title_box {
  font-size: 20px;
  padding: 15px 30px;
  line-height: 1;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  display: inline-block;
  margin: 0 0 50px;
  background-color: #fff;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  position: sticky;
  top: 70px;
  z-index: 7;
  display: inline-flex;
  align-items: center;
  gap: 25px;
}
section.about__korea__india .title_box img {
  height: 30px;
}
section.switch__tabs ul.nav-tabs {
  justify-content: center;
  border: none;
  gap: 10px;
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 10px rgb(0 0 0 / 25%);
  width: 80%;
  margin: 0 auto;
}
section.switch__tabs ul.nav-tabs li.nav-item {
  flex: 1;
}
section.switch__tabs ul.nav-tabs li.nav-item button {
  border: solid 2px var(--primary400);
  border-radius: 7px 7px;
  font-size: 22px;
  font-weight: 600;
  color: #000;
  width: 100%;
}
section.switch__tabs ul.nav-tabs li.nav-item button.active {
  background-color: var(--primary400);
  color: #fff;
  border: solid 2px var(--primary400);
}
section.switch__tabs .tab-content {
  margin-top: 40px;
}
img.connect_logo {
  height: 15px;
}
.comparision_img {
  width: 75%;
}

.product.hidden {
  display: none;
}
/* section.product__wrapper {
  visibility: hidden;
} */
.pagination__wrapper ul li.page-item {
  list-style: none;
  padding: 5px;
  margin: 10px;
  width: 60px;
  box-sizing: border-box;
  background-color: var(--primary400);
  text-align: center;
}
.pagination__wrapper ul li.page {
  list-style: none;
}
.pagination__wrapper {
  align-items: center;
}
section.pagination_wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
}
.pagination_box {
  display: flex;
  align-items: center;
  background-color: white;
  color: black;
  padding: 10px 40px;
  border-radius: 6px;
}
.pagination_box ul {
  margin: 0 15px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 15px;
}
.pagination_box ul li,
.pagination_box button.nav_btn {
  list-style: none;
  display: inline-block;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  font-size: 20px;
  font-weight: 400;
  border: solid 1px #ddd;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination_box ul li.active {
  background-color: var(--primary400);
  color: #fff;
  border-color: var(--primary400);
}
.btn1 .btn2 {
  display: inline-flex;
  align-items: center;
  font-size: 22px;
  font-weight: 400;
  color: grey;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.mystyle {
  display: none;
  background-color: red;
  padding-top: 200px;
}
.mysameer {
  display: block;
}

.sub_page_header {
  background-color: var(--primary400);
  color: #fff;
  padding: 15px 0;
}
.sub_page_header .logo {
  display: flex;
  gap: 5px;
  align-items: flex-end;
}
.sub_page_header .logo span {
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
  margin-top: 2px;
  line-height: 1;
  text-align: centers;
}
.popup_gif_right {
  position: relative;
}
.popup_gif_right img.loader {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 25px;
  transform: translateY(-50%);
}
iframe.remove {
  display: none;
}
section.Whole__form__wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
section.keywords_wrap {
  padding: 30px 0 22px;
  font-size: 14px;
  background-color: #dbdbdb;
}

section.keywords_wrap a {
  text-decoration: none;
  line-height: 1.1;
  position: relative;
  padding: 0 0 8px 12px;
  color: #777;
}
section.keywords_wrap a:hover{  
  color: #616161;
}
section.keywords_wrap a::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #9c9c9c;
  border-radius: 50%;
  left: 0;
  top: 2px;
}
.whatsapp_btn{
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #25d366;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #fff;
  font-size: 36px;
  z-index: 9;
}
@media screen and (min-width: 768px) {
  .d-md-none {
    display: none;
  }
}
@media screen and (min-width: 991px) {
  .bottom__header {
    background-color: #fff;
    padding: 10px 0;
    transform: initial;
  }
}
@media screen and (max-width: 767px) {
  section.product__wrapper .first__image img.product_image {
    height: auto;
    width: 100%;
  }
  .subpage_footer .footer__item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .city__address {
    width: 100%;
  }
  .city__address li {
    text-align: center;
  }
  .sub_page_header .logo {
    flex-direction: column;
    align-items: flex-start;
  }
  section.inner__banner.blog_detail_banner h1 {
    font-size: 25px;
  }

  section.inner__banner.blog_detail_banner {
    height: auto;
    padding: 40px 0;
  }
  section.inner__banner.blog_detail_banner img {
    transform: scale(2);
  }
  section.how_can_it_help .list__single {
    flex-direction: column;
    align-items: center;
  }
  h1.healthyLife_title span {
    margin-left: 17%;
  }
  section.product__wrapper .first__image {
    order: 1;
  }
  section.product__wrapper .content__box {
    order: 2;
  }
  ul.tabs-history li.nav-item button {
    width: 120px;
  }
  section.different_php_level .video_box {
    height: 198px;
  }
  .review_wrapper .readmore__btn {
    padding: 9px 15px;
  }
  .comparision_img {
    width: 100%;
  }
  .splash__img {
    width: 100%;
    height: 100%;
  }
  .en7p__image {
    width: 100%;
    height: 100%;
  }
  /* .first__image {
    width: 100%;
    height: 100%;
  } */
  ul.tabs-history {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  ul.tabs-history::-webkit-scrollbar {
    height: 10px;
  }
  .list__about {
    column-count: inherit;
  }
  .faq__wrapper button.accordion-button,
  .faq__wrapper .accordion-body {
    padding: 10px;
  }
  section.live__ionia__water .live_with_ionia {
    margin-top: -50px;
  }
  section.faq__wrapper .faq_tab ul.nav-tabs button {
    padding: 10px;
  }
  section.faq__wrapper .faq_tab ul.nav-tabs {
    width: 100px;
  }
  section.faq__wrapper .faq_tab {
    gap: 10px;
  }
  section.faq__wrapper .faq_tab .tab-content {
    padding: 0;
  }
  section.faq__wrapper .faq_tab ul.nav-tabs button::after,
  section.faq__wrapper .faq_tab ul.nav-tabs button.active::after {
    border-top: 15px solid transparent;
    border-right: 0 solid transparent;
    border-left: 10px solid var(--gray400);
    border-bottom: 15px solid transparent;
  }
  section.faq__wrapper .faq_tab ul.nav-tabs button::after {
    top: 50%;
    transform: translateY(-50%);
    right: -8px;
  }
  section.faq__wrapper .faq_tab ul.nav-tabs button.active::after {
    border-left: 10px solid var(--primary400);
  }
  section.rnd_product__wrapper ul.nav-tabs {
    flex-wrap: nowrap;
    overflow: auto;
    padding: 0 15px;
  }
  section.rnd_product__wrapper ul.nav-tabs li.nav-item {
    flex: 0 0 auto;
  }
  section.rnd_product__wrapper ul.nav-tabs::-webkit-scrollbar {
    display: none;
  }
  .product_detail_info .product_slide_image img.thumb__image {
    height: auto;
    width: 100%;
  }
  section.gallery__wrapper .gallery_img {
    height: 120px;
  }
  section.performace_of_electrod__wrapper .title__wrap {
    margin-top: 20px;
  }
  section.performace_of_electrod__wrapper .title__wrap h2 span {
    font-size: 20px;
  }
  section.video__banner .bannerText4 h2 span {
    font-size: 20px;
    background-color: rgb(0 0 0 / 45%);
    display: inline-block;
    padding: 2px 10px;
    margin-top: 5px;
  }
  section.video__banner .bannerText3,
  section.video__banner .bannerText4 {
    max-width: 60%;
    left: 10%;
  }
  section.video__banner .bannerText3 h2 span {
    font-size: 14px;
  }
  section.video__banner a[role="button"] {
    width: 30px;
    height: 30px;
  }
  section.video__banner .bannerText1 {
    max-width: 50%;
    left: 60%;
  }
  section.video__banner .bannerText2 {
    left: 12%;
    max-width: 50%;
  }
  section.video__banner .bannerText2 h2 span {
    font-size: 24px;
  }
  section.video__banner .bannerText1 h2,
  section.video__banner .bannerText2 h2,
  section.video__banner .bannerText3 h2,
  section.video__banner .bannerText4 h2 {
    font-size: 20px;
  }
  section.exporting_countries__wrapper .item {
    width: 150px;
    height: 150px;
  }
  .patent__overlay svg {
    fill: #000;
  }
  .patent__overlay .prevBtn {
    left: 0;
  }
  .patent__overlay .nextBtn {
    right: 0;
  }
  .patent__overlay .modal_image {
    max-width: 90%;
    max-height: inherit;
    height: auto;
    transform: translateX(-50%);
    left: 50%;
    position: relative;
  }
  section.testimonials__wrapper .thumb {
    height: 40px;
    width: 40px;
    object-fit: cover;
  }
  section.blog_detail__wrapper {
    padding: 40px 15px;
  }
  section.switch__tabs ul.nav-tabs {
    width: 100%;
  }
  .switch__tabs .about_tab_image {
    position: static;
  }
  section.inner__banner.health_benefit_banner img,
  section.inner__banner.product_banner_wrapper img.banner {
    transform: scale(11.5);
  }
  section.health_benefit_banner .video_box {
    margin: 20px 0 0;
    width: 100%;
  }
  section.inner__banner.health_benefit_banner {
    height: auto;
    padding: 50px 0 100px;
  }
  .demo {
    background-repeat: no-repeat;
    overflow: hidden;
  }
  section.inner__banner h1,
  section.rnd_banner__wrapper h1 {
    font-size: 35px;
  }
  section.inner__banner {
    align-items: flex-start;
    padding: 40px 0 0;
    height: 15vh;
    margin: 0;
  }
  section.inner__banner img {
    top: 0;
  }
  .sticky .navbar .navbar-nav {
    gap: 0;
  }
  .navbar a.nav-link {
    border: none;
  }
  .navbar a.nav-link.active {
    color: var(--primary400);
    border: none;
  }
  .demo .img {
    width: 100%;
    height: auto;
  }
  .demo:hover .img {
    display: block;
    width: 100%;
    pointer-events: none;
  }
  .contact_us__page_wrapper .right_infobar {
    justify-content: flex-start;
    gap: 30px;
  }
  section.contact_us__wrapper .map {
    height: 300px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 991px) {
  section.video__banner {
    height: 765spx;
  }
  section.banner__text h2 {
    font-size: 35px;
  }
  section.banner__text h2 span {
    font-size: 60px;
  }
  .title__wrap h2 {
    font-size: 40px;
  }
  .navbar .navbar-nav {
    gap: 35px;
    align-items: center;
  }
  .navbar a.nav-link {
    border-bottom: solid 2px transparent;
  }
  .navbar a.nav-link {
    width: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
    padding: 5px 0;
  }
  .navbar a.nav-link.btn {
    background-color: var(--primary400);
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    transition: 0.5s ease;
    line-height: 1;
  }
  .navbar a.nav-link.btn.active {
    border-bottom: none;
  }
  section {
    padding: 90px 0;
    background-color: #fff;
    z-index: 1;
  }

  section.who_we_are__wrapper .title__wrap .wave__img {
    height: 23px;
  }
  section.why_ionia__wrapper {
    padding: 90px 0;
  }
  section.why_ionia__wrapper .ionia_item {
    align-items: center;
  }
  section.why_ionia__wrapper .ionia_item .icon {
    width: 60px;
    height: 60px;
    font-size: 25px;
  }
  section.why_ionia__wrapper .ionia_item .icon img {
    height: 30px;
    width: 30px;
  }
  section.video__banner .banner__text h2 {
    font-weight: 600;
    font-size: 35px;
  }
  /* section.different_php_level .content__box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  } */
  section.exporting_countries__wrapper .item {
    width: 160px;
    height: 160px;
  }
  section.exporting_countries__wrapper.bottom {
    padding-top: 150px;
  }
  section.exporting_countries__wrapper .title h2 {
    font-size: 4rem;
  }
  section.testimonials__wrapper .thumb {
    margin-bottom: 0;
  }
}
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}
@media screen and (max-width: 991px) {
  section.video__banner .banner__text h1 {
    text-align: center;
    font-size: 35px;
  }
  .our_networks h2 {
    background-color: red;
  }
  section.image1_picture {
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--primary400);
  }
  section.mission_vision_picture {
    background-color: red;
    top: 0;
    left: 0;
    width: 100%;
  }
  .help_section_bg {
    object-fit: cover;
    background-size: auto;
  }
  /* @media screen and (min-width: 991px) {
    section.patent__wrapper {
      height: "100%";
      width: "100%";
      padding: 10px 0;
    }
  } */
}
@media screen and (min-width: 992px) {
  section.rnd_product__wrapper ul.nav-tabs {
    max-width: 960px;
  }
}
@media screen and (min-width: 1200px) {
  section.rnd_product__wrapper ul.nav-tabs {
    max-width: 1140px;
  }
}
@media screen and (min-width: 1400px) {
  section.rnd_product__wrapper ul.nav-tabs {
    max-width: 1320px;
  }
}
iframe#kiwi-big-iframe-wrapper {
  right: 10px;
}

div#fc_frame {
  left: 15px;
}

.center-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}
.header_bg{
  background-color: gray;
  color: #000;
  font-weight: bold;
}
.bordered-table {
  border-collapse: collapse;
}

.bordered-table th,
.bordered-table td {
  border: 1px solid black;
}
.Bill-Ship-Head{
  background-color: gray;
  color: #000;
  font-weight: bold;
  /* padding: 5px; */
  border:black;
}
table.component_table{
 width: 950px;
}
.tbl_col{
  padding: 4px 10px;
  color: #000;
  border-bottom: solid 1px #000; 
  text-align: center;
}

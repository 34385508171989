.footer__wrapper {
  background: transparent
    linear-gradient(
      270deg,
      var(--gradientColor3) 0%,
      var(--gradientColor2) 100%,
      var(--gradientColor1) 100%
    )
    0% 0% no-repeat padding-box;
  color: #fff;
  padding: 40px 0;
}
.footer__logo {
  height: 22px;
  width: auto;
  margin: 0 0 37px;
  object-fit: contain;
}
.quick__links {
  list-style: none;
  padding: 0;
  margin: 0;
  column-count: 2;
  column-gap: 0;
}
.quick__links li,
.details__box li {
  padding: 4px 0;
}
.quick__links li a {
  color: #fff;
  text-decoration: none;
}
.details__box {
  list-style: none;
  padding: 0;
  margin: 0;
}
.details__box a {
  color: #fff;
  text-decoration: none;
  margin-left: 5px;
}
.titles {
  font-size: 22px;
  font-weight: 400;
  margin: 30px 0;
}
@media screen and (min-width: 768px) {
  .titles {
    margin: 0 0 30px;
  }
}
